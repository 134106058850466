import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import {
  FaChalkboardTeacher,
  FaBook,
  FaUsers,
} from "react-icons/fa";
import AdminCursos from "./Submenus/AdminCursos";
import AdminDocentes from "./Submenus/AdminDocentes";
import AdminRoles from "./Submenus/AdminRoles";
import CursoModulo from "./SubCursos/CursoModulo";
import { url } from "../../../../config";
import SessionManager from "../../../SessionManagement.js";
import CerrarSesion from "../../../CerrarSesion.js"
import AdminEnrolamiento from "./Submenus/AdminEnrolamiento.jsx";
import AdminEnrolamientoDocentes from "./Submenus/AdminEnrolamientoDocentes.jsx";
import AdminControlExamen from "./Submenus/AdminControlExamen.jsx";
import AdminControlUsuarios from "./Submenus/AdminControlUsuarios.jsx";
import AdminControlMenus from "./Submenus/AdminControlMenus.jsx";

function Administracion(props) {

  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  }
  const baseUrl = url;
  const [userSubMenus, setUserSubMenus] = useState([]);
  const [rutassub, setRutassub] = useState([]);

  const [activeOpcion, setActiveOpcion] = useState("Cursos");
  const [contenidoa, setcontenidoa] = useState(<AdminCursos />);

  const handleMenuClick = (opcion, vista) => {
    // Remover la propiedad "selected" del enlace "Tablero" si se selecciona otra opción
    setActiveOpcion(opcion);
    setcontenidoa(vista);
    const usuarioLink = document.querySelector("li.selected a");
    if (usuarioLink) {
      usuarioLink.classList.remove("selected");
    }
  };

  // Cerrar sesión


  const cookies = new Cookies();

  useEffect(() => {
    // Obtener datos del usuario e información pertinente
    const fetchUserInfo = async () => {
      try {
        const token = cookies.get("token");
        const response = await axios.get(baseUrl + "usuarios/userdata", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setUserSubMenus(userData.submenus);
        setRutassub(userData.rutassub);
      } catch (error) {
        console.error(error);
        // Maneja el error de la solicitud al backend
      }
    };
    fetchUserInfo();


  }, []);

  useEffect(() => {
    const index = userSubMenus.indexOf(activeOpcion);
    if (index !== -1) {
      setcontenidoa(
        activeOpcion === "Cursos" ? (
          <AdminCursos />
        ) : activeOpcion === "Docentes" ? (
          <AdminDocentes />
        ) : activeOpcion === "Roles" ? (
          <AdminRoles />
        ) : activeOpcion === "Enrolamiento Estudiantes" ? (
          <AdminEnrolamiento/>
        ): activeOpcion === "Enrolamiento Docentes" ? (
          <AdminEnrolamientoDocentes/>
        ): activeOpcion === "Control Examen" ? (
          <AdminControlExamen/>
        ): activeOpcion === "Control Usuarios" ? (
          <AdminControlUsuarios/>
        ): activeOpcion === "Control Menú - Roles" ? (
          <AdminControlMenus/>
        )
         : activeOpcion === "Modulo" ? (
          <CursoModulo /> // Agregar CursoModulo como una opción válida
        ) : null
      );
    } else {
      setcontenidoa(null);
    }
  }, [activeOpcion, userSubMenus]);

  return (
    <Fragment>
      <div className="row">

        {/* Barra de Navegación en Dispositivos Móviles */}
        <div className="col-12 d-md-none">
          <div className="d-flex justify-content-between">
            {userSubMenus.map((submenu, index) => (
              <div key={index} className={`orange-box ${activeOpcion === submenu ? "selected" : ""}`} style={{ width: "40%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {/* Icono condicional */}
                  {submenu === "Cursos" && <FaBook size={100} style={{ marginTop: "8px" }} />}
                  {submenu === "Docentes" && <FaChalkboardTeacher size={100} style={{ marginTop: "8px" }} />}
                  {submenu === "Roles" && <FaUsers size={100} style={{ marginTop: "8px" }} />}
                  {submenu === "Enrolamiento Estudiantes" && <FaUsers size={100} style={{ marginTop: "8px" }} />}
                  {submenu === "Enrolamiento Docentes" && <FaUsers size={100} style={{ marginTop: "8px" }} />}
                  {submenu === "Control Examen" && <FaUsers size={100} style={{ marginTop: "8px" }} />}
                  {submenu === "Control Usuarios" && <FaUsers size={100} style={{ marginTop: "8px" }} />}
                  {submenu === "Control Menú - Roles" && <FaUsers size={100} style={{ marginTop: "8px" }} />}

                  {/* Contenido del recuadro */}
                  <Link
                    to="#"
                    onClick={() => handleMenuClick(submenu, rutassub[index])}
                    className="custom-uppercase"
                    style={{
                      pointerEvents: activeOpcion === submenu ? "none" : "auto",
                      color: activeOpcion === submenu ? "white" : "white",
                      fontSize: "14px",
                      textDecoration: "none",
                      marginTop: "8px", 
                    }}
                  >
                    {submenu}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>



        <div className="col-md-2 col-12 bajando  d-none d-md-block">
          <div
            style={{
              background: "#FFA778",
              borderRadius: "20px",
              width: "300px",
              height: `${userSubMenus.length * 72.5}px`,
              color: "#fff",
            }}
          >
            <ul style={{ listStyleType: "none", padding: "0" }}>
              {userSubMenus.map((submenu, index) => (
                <li
                  key={index}
                  className={
                    activeOpcion === submenu
                      ? "selected custom-uppercase"
                      : "custom-uppercase"
                  }
                  style={{
                    display: "flex",
                    alignItems: "center", // Centrar verticalmente el contenido
                    justifyContent: "center",
                    color: "#fff", // Centrar horizontalmente el contenido
                  }}
                >
                  <Link
                    to="#"
                    onClick={() => handleMenuClick(submenu, rutassub[index])}
                    className={activeOpcion === submenu ? "selected" : ""}
                    style={{
                      pointerEvents: activeOpcion === submenu ? "none" : "auto",
                      color: activeOpcion === submenu ? "white" : "white",
                    }}
                  >
                    {submenu}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="col-md-10 col-12">
          <div style={{ marginLeft: "20px" }}>{contenidoa}</div>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(Administracion);
