import React, { Fragment, useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { url } from "../../../../../config.js";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faMagnifyingGlass,
  faLock,
  faLockOpen,
  faRotateRight,
  faPlus,
  faSchool,
  faChalkboardUser,
  faUserSecret,
  faUser,
  faToggleOn,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FaWindowClose } from "react-icons/fa";

const AdminControlMenus = (props) => {
  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  };

  const baseUrl = url;
  const cookies = new Cookies();

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(11);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const cargarMenuRoles = async () => {
    const token = cookies.get("token");
    const lista = await axios.get(
      baseUrl + "Enrolamiento/Obtener_MenuXRoles_ControlUsuarios",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setData(lista.data);
  };

  const HandleAsignar_Quitar_MenuXRoles = async (condicion, menu, menurol) => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas Actualizar el Acceso al Menú del Rol Seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await Asignar_Quitar_MenuXRoles(condicion, menu, menurol);
    } else {
      return;
    }
  };

  const Asignar_Quitar_MenuXRoles = async (condicion, menu, menurol) => {
    try {
      const body = {
        IdCondicion: condicion,
        IdMenu: menu,
        IdMenuRol: menurol,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/Asignar_Quitar_MenuXRoles_ControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        cargarMenuRoles();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  useEffect(() => {
    cargarMenuRoles();
  }, []);

  return (
    <Fragment>
      <div className="admin-enrolamiento-container">
        <h1 style={{ marginBottom: "20px", textAlign: "center" }}>
          Control Menú - Roles
        </h1>

        <div
          className="table-responsive"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          <table className="table table-striped table-bordered table-light">
            <thead className="thead-dark">
              <tr>
                <th>Acciones</th>
                <th>Menú</th>
                <th>Menú Padre</th>
                <th>Rol Estudiante</th>
                <th>Rol Docente</th>
                <th>Rol Administrador</th>
                <th>Rol Administrador Funcional</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            backgroundColor: "#F07436",
                            color: "white",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                          style={{
                            width: "auto",
                          }}
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() =>
                              HandleAsignar_Quitar_MenuXRoles(
                                1,
                                item.id_menu,
                                item.id_menurol_estudiante
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faSchool} /> Asignar/Quitar
                              Rol Estudiante
                            </b>
                          </button>

                          <button
                            class="dropdown-item"
                            type="button"
                            onClick={() =>
                              HandleAsignar_Quitar_MenuXRoles(
                                2,
                                item.id_menu,
                                item.id_menurol_docente
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faChalkboardUser} />
                              Asignar/Quitar Rol Docente
                            </b>
                          </button>
                          <button
                            class="dropdown-item"
                            type="button"
                            onClick={() =>
                              HandleAsignar_Quitar_MenuXRoles(
                                3,
                                item.id_menu,
                                item.id_menurol_admin
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faUserSecret} />
                              Asignar/Quitar Rol Administrador
                            </b>
                          </button>
                          <button
                            class="dropdown-item"
                            type="button"
                            onClick={() =>
                              HandleAsignar_Quitar_MenuXRoles(
                                4,
                                item.id_menu,
                                item.id_menurol_admin_funcional
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faUserSecret} />
                              Asignar/Quitar Rol Admin Funcional
                            </b>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <b>{item.nombre_menu}</b>
                    </td>
                    <td>
                      <b style={{ color: "red" }}>{item.menu_padre}</b>
                    </td>
                    <td>
                      <b>{item.es_estudiante}</b>
                    </td>
                    <td>
                      <b>{item.es_docente}</b>
                    </td>
                    <td>
                      <b>{item.es_admin}</b>
                    </td>
                    <td>
                      <b>{item.es_admin_funcional}</b>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No hay datos disponibles
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary btn-sm"
          >
            Anterior
          </button>
          <span style={{ margin: "0 10px" }}>Página {currentPage}</span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= data.length}
            className="btn btn-secondary btn-sm"
          >
            Siguiente
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(AdminControlMenus);
