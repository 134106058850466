import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import jwt_decode from "jwt-decode";
import { url } from "../../../../../../config";
import SessionManager from "../../../../../SessionManagement.js";
import CerrarSesion from "../../../../../CerrarSesion.js";

function ExamenResolution(props) {
  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  };
  // const baseUrl = "https://localhost:44322/api/";
  const baseUrl = url;
  const { idExamen } = props;
  const { idCurso } = props;
  const { inten_hechos } = props;
  const [nombreClase, setNombreClase] = useState("");
  const [id_examen, setid_examen] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [nombreexamen, setNombreExamen] = useState([]);
  const [examenPreguntas, setExamenPreguntas] = useState([]);
  const [opcionesPorPregunta, setOpcionesPorPregunta] = useState({});
  const [preguntaActual, setPreguntaActual] = useState(0);
  const [examCompleted, setExamCompleted] = useState(false);
  const [respuestas, setRespuestas] = useState([]);
  const [listarespuestas, setListaRespuestas] = useState({});
  const [userAnswers, setUserAnswers] = useState({});

  const [nuevaRespuesta, setnuevaRespuesta] = useState({
    IdExamen_respuesta: "",
    IdExamen_pregunta_opciones: "",
  });

  // Capturando datos para el nuevaRespuesta.
  const ModuloRespuesta = {
    IdExamen_respuesta: parseInt(nuevaRespuesta.id_examen),
    IdExamen_pregunta_opciones: parseInt(
      nuevaRespuesta.IdExamen_pregunta_opciones
    ),
  };

  //
  // Cerrar sesión

  const cookies = new Cookies();

  // Check if all questions have been answered
  const allQuestionsAnswered = examenPreguntas.every((pregunta) => {
    return (
      opcionesPorPregunta[pregunta.idExamen_preguntas] && selectedOption !== ""
    );
  });

  // Define your submitExamResponse function

  const handleSubmitExamen = async () => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas enviar tus respuestas y terminar el Examen?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, enviar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await submitExamResponse2();
    } else {
      return;
    }
  };

  const submitExamResponse = async () => {
    try {
      const token = cookies.get("token");

      Swal.fire({
        title: "Enviando respuestas...",
        text: "Por favor espera mientras se envían las respuestas.",
        icon: "info",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      for (const preguntaId in listarespuestas) {
        const respuestaId = listarespuestas[preguntaId];

        const requestBody = {
          idExamen: idExamen,
          idExamen_respuesta: id_examen,
          idExamen_pregunta_opciones: respuestaId,
          inten_hechos: inten_hechos + 1,
        };

        const result = await axios.post(
          baseUrl + "Enrolamiento/registrarExamen_respuesta_detalle",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status !== 200) {
          throw new Error("Error al enviar una de las respuestas.");
        }
      }

      Swal.close();

      let timerInterval;
      Swal.fire({
        icon: "success",
        title: "Examen Realizado",
        html: 'Examen Realizado correctamente!<br>Aplicando cambios, será redireccionado al inicio: <b></b><br> <small>[Si aparece una alerta de navegador, por favor presionar "Abandonar" o "Salir"]</small>',
        showConfirmButton: false,
        timer: 5000, // duración del temporizador en milisegundos
        timerProgressBar: true,
        didOpen: () => {
          timerInterval = setInterval(() => {
            const content = Swal.getHtmlContainer();
            const b = content ? content.querySelector("b") : null;
            if (b) {
              // Actualizar el texto para mostrar la cuenta regresiva en segundos
              b.textContent = Math.round(Swal.getTimerLeft() / 1000);
            }
          }, 100); // Actualizar cada 100 milisegundos
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        // Si el usuario cierra el modal haciendo clic en el botón de confirmación o se acaba el temporizador, redirigir al inicio
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log("I was closed by the timer");
        }
        window.location.href = "/menu";
      });

      // Llamar a enviandodatos después de completar submitExamResponse
      await enviandodatos();
    } catch (error) {
      console.error(error);
      Swal.close(); // Cerrar el SweetAlert de carga en caso de error
      // Manejar errores mostrando un SweetAlert
      Swal.fire({
        title: "Error",
        text: "Ocurrió un problema al enviar las respuestas.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const enviandodatos = async () => {
    try {
      const token = cookies.get("token");
      const requestBody = { idExamen_respuesta: id_examen };
      const result = await axios.post(
        baseUrl + "Examen_respuesta/enviarExamen_respuesta",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  /*const submitExamResponse2 = async () => {
    try {
      const token = cookies.get("token");

      Swal.fire({
        title: "Enviando respuestas...",
        text: "Espere mientras se envían las respuestas.",
        icon: "info",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      for (const preguntaId in listarespuestas) {
        const respuestaId = listarespuestas[preguntaId];

        const requestBody = {
          IdExamen: idExamen,
          IdExamenRespuesta: id_examen,
          IdExamenPreguntaOpciones: respuestaId,
          inten_hechos: inten_hechos + 1,
        };

        const result = await axios.post(
          baseUrl + "Enrolamiento/registrarExamen_respuesta_detalle",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result.status !== 200) {
          throw new Error("Error al enviar una de las respuestas.");
        }
      }
      await ActualizarCabeceraExamenRespuesta(id_examen);
    } catch (error) {
      Swal.close();
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };*/

  const submitExamResponse2 = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    window.onbeforeunload = () => {
      controller.abort();
    };

    try {
      const token = cookies.get("token");

      Swal.fire({
        title: "Enviando respuestas...",
        text: "Espere mientras se envían las respuestas.",
        icon: "info",
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const listaDetalle = [];

      for (const preguntaId in listarespuestas) {
        const respuestaId = listarespuestas[preguntaId];

        const requestBody = {
          IdExamen: idExamen,
          IdExamenRespuesta: id_examen,
          IdExamenPreguntaOpciones: respuestaId,
        };
        listaDetalle.push(requestBody);
      }

      const result = await axios.post(
        baseUrl + "Enrolamiento/registrarExamen_respuesta_detalle",
        listaDetalle,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          signal: signal,
        }
      );

      const estadoMensaje = result.data.message_status;
      const mensaje = result.data.message;

      Swal.close();

      Swal.fire({
        icon: estadoMensaje || "error",
        title: "Oben Academy",
        text: mensaje || "Ocurrió un error al procesar las respuestas.",
      }).then((result) => {
        if (result.dismiss || result.isConfirmed) {
          window.location.href = "/menu";
        }
      });
    } catch (error) {
      if (error.name === "AbortError") {
      } else {
        Swal.close();
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.dismiss || result.isConfirmed) {
            window.location.href = "/menu";
          }
        });
      }
    } finally {
      window.onbeforeunload = null;
    }
  };

  const ActualizarCabeceraExamenRespuesta = async (id) => {
    try {
      const body = {
        IdExamenRespuesta: id,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/ActualizarCabeceraExamenRespuesta`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
          allowOutsideClick: true,
          confirmButtonText: "Aceptar",
        }).then(() => {
          window.location.href = "/menu";
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
          allowOutsideClick: true,
          confirmButtonText: "Aceptar",
        }).then(() => {
          window.location.href = "/menu";
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const fetchExamen = async () => {
    try {
      const token = cookies.get("token");
      const requestBody = { idExamen: idExamen };
      const result = await axios.post(
        baseUrl + "examen/buscarExamenSolo",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.status === 200) {
        setNombreExamen(result.data);
      } else {
        // Realizar alguna acción en caso de que la respuesta no sea exitosa
      }
    } catch (error) {
      console.error(error);
      // Manejar el error de la solicitud al backend
    }
  };

  // Function to register exam response detail
  const registerExamResponseDetail = async () => {
    try {
      const token = cookies.get("token");

      if (!selectedOption) {
        // If no answer is selected, do nothing
        return;
      }

      // Create a copy of the userAnswers object
      const updatedUserAnswers = { ...userAnswers };

      // Store the selected option for the current question
      updatedUserAnswers[examenPreguntas[preguntaActual].idExamen_preguntas] =
        selectedOption;
      // Send the updated userAnswers to the server for grading
      const response = await axios.post(
        baseUrl + "Examen_respuesta_detalle/registrarExamen_respuesta_detalle",
        {
          IdExamen_respuesta: id_examen,
          IdExamen_pregunta_opciones: selectedOption,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        // Update the userAnswers state with the updatedUserAnswers
        setUserAnswers(updatedUserAnswers);

        // If the exam is completed, you can proceed to submit the entire exam
        if (preguntaActual === examenPreguntas.length - 1) {
          handleSubmit();
        } else {
          // Move to the next question if not the last question
          handleSiguientePregunta();
        }
      } else {
        // Handle errors or show a message to the user
      }
    } catch (error) {
      console.error(error);
      // Handle errors or show a message to the user
    }
  };

  useEffect(() => {
    const fetchPreguntas = async () => {
      try {
        const token = cookies.get("token");
        const requestBody = { idExamen: idExamen };
        const result = await axios.post(
          baseUrl + "examen/vistaPreviaExamen",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          setExamenPreguntas(result.data.examenPreguntas);
          setOpcionesPorPregunta(result.data.opcionesPorPregunta);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    const fetchNombreCurso = async () => {
      try {
        const token = cookies.get("token");
        const requestBody = { idCurso: idCurso };
        const result = await axios.post(
          baseUrl + "cursos/buscarCurso",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          setNombreClase(result.data);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    //obteniendo datos iniciales idExamen_respesta, idexamen,idusuario,
    const fetchIniciadorExamen = async () => {
      try {
        const token = cookies.get("token");
        const requestBody = { idExamen: idExamen };
        const result = await axios.post(
          baseUrl + "Examen_respuesta/listarExamen_respuesta",
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.status === 200) {
          const { idExamen_respuesta } = result.data;
          setid_examen(idExamen_respuesta);
        } else {
          // Realizar alguna acción en caso de que la respuesta no sea exitosa
        }
      } catch (error) {
        console.error(error);
        // Manejar el error de la solicitud al backend
      }
    };

    fetchIniciadorExamen();
    fetchNombreCurso();
    fetchPreguntas();
    fetchExamen();

    if (allQuestionsAnswered) {
      setExamCompleted(true);
    }
  }, [selectedOption]);

  // Manejar el avance a la siguiente pregunta
  const handleSiguientePregunta = () => {
    if (selectedOption !== "") {
      const respuestaActual = {
        preguntaId: examenPreguntas[preguntaActual].idExamen_preguntas,
        opcionId: selectedOption,
      };
      setRespuestas([...respuestas, respuestaActual]);

      if (preguntaActual < examenPreguntas.length - 1) {
        setPreguntaActual(preguntaActual + 1);
        setSelectedOption("");
      }
    }
  };

  const submitExamResponsev2 = () => {
    console.log("Respuestas almacenadas:", listarespuestas);
  };

  // Replace your existing handleSubmit function
  const handleSubmit = async () => {
    try {
      // Call the submitExamResponse function to handle the exam submission
      const response = await submitExamResponse();

      if (response.status === 200) {
        // Exam submission was successful, you can handle the response as needed
      } else {
        // Handle errors or show a message to the user
      }
    } catch (error) {
      console.error(error);
      // Handle errors or show a message to the user
    }
  };

  // ALERTAS PARA NO ABANDONAR EL EXAMEN-----------------------------------------------------------------------
  /*window.addEventListener("beforeunload", function (e) {
    // Cancela el evento de cierre predeterminado
    e.preventDefault();
    // Mensaje personalizado para el usuario
    var message =
      "¿Estás seguro de que quieres abandonar la página? Los cambios no guardados se perderán.";
    // Establece el mensaje en el evento
    e.returnValue = message;
    // Devuelve el mensaje (la implementación de returnValue es para navegadores más antiguos)
    return message;
  });*/

  useEffect(() => {
    const handlePopState = (event) => {
      // Evitar que la acción predeterminada ocurra
      event.preventDefault();

      // Mostrar SweetAlert2
      Swal.fire({
        title: "¿Estás seguro?",
        text: "¿Estás seguro de que quieres abandonar la evaluación? Tus respuestas no se enviarán.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // Si hacen clic en "Aceptar", realiza la redirección
        if (result.isConfirmed) {
          window.location.href = "/menu";
        }
      });
    };

    // Agregar el event listener
    window.addEventListener("popstate", handlePopState);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    // Reiniciar listarespuestas a un objeto vacío cuando el componente se monta o actualiza
    setListaRespuestas({});
  }, []);

  // -----------------------------------------------------------------------

  return (
    <Fragment>
      <div>
        <Container className="my-5 p-3 bg-light border rounded">
          <div style={{ display: "flex", alignItems: "center" }}>
            {nombreClase.nom_curso && (
              <h5 style={{ margin: "0", marginRight: "10px" }}>
                {nombreClase.nom_curso}
              </h5>
            )}
            <span style={{ fontSize: "12px", color: "#888" }}>{"\u003E"}</span>
            <h5 style={{ margin: "0", marginLeft: "10px" }}>Modulo</h5>
            <span style={{ fontSize: "12px", color: "#888" }}>{"\u003E"}</span>
            <h5 style={{ margin: "0", marginLeft: "10px" }}>Examenes</h5>
            <span style={{ fontSize: "12px", color: "#888" }}>{"\u003E"}</span>

            <h5
              className="truncate-examentitle"
              style={{ margin: "0", marginLeft: "10px" }}
            >
              {nombreexamen.titulo}
            </h5>
          </div>
          <hr />
          <h1>Examen</h1>
          <h3>Lista de preguntas de examen</h3>
          <main
            style={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {examenPreguntas[preguntaActual] ? (
              <div key={examenPreguntas[preguntaActual].idExamen_preguntas}>
                <div className="recuadro-naranja-vprevia">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "10px",
                      backgroundColor: "#F47735",
                      padding: "5px 10px",
                      borderRadius: "3px",
                    }}
                  >
                    <h2 style={{ textAlign: "center", margin: "0" }}>
                      {examenPreguntas[preguntaActual].descripcion_pregunta}
                    </h2>
                    <p
                      style={{
                        backgroundColor: "white",
                        color: "#F47735",
                        padding: "5px 10px",
                        borderRadius: "3px",
                      }}
                    >
                      Puntos: {examenPreguntas[preguntaActual].puntos}
                    </p>
                  </div>
                  {opcionesPorPregunta[
                    examenPreguntas[preguntaActual].idExamen_preguntas
                  ] &&
                    opcionesPorPregunta[
                      examenPreguntas[preguntaActual].idExamen_preguntas
                    ].map((opcion) => {
                      // Verificar si la opción actual está seleccionada
                      const estaSeleccionada =
                        listarespuestas[
                          examenPreguntas[preguntaActual].idExamen_preguntas
                        ] === opcion.idExamen_pregunta_opciones;
                      return (
                        <label
                          key={opcion.idExamen_pregunta_opciones}
                          className={`opcion-label ${
                            estaSeleccionada ? "seleccionada" : ""
                          }`}
                          onClick={() => {
                            setSelectedOption(
                              opcion.idExamen_pregunta_opciones
                            );
                            const preguntaId =
                              examenPreguntas[preguntaActual]
                                .idExamen_preguntas;
                            setListaRespuestas((prevListaRespuestas) => ({
                              ...prevListaRespuestas,
                              [preguntaId]: opcion.idExamen_pregunta_opciones,
                            }));
                          }}
                          style={{
                            backgroundColor: "#F47735",
                            marginBottom: "10px",
                            padding: "10px",
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                          }}
                        >
                          <input
                            type="radio"
                            name={`respuesta_${examenPreguntas[preguntaActual].idExamen_preguntas}`}
                            value={opcion.idExamen_pregunta_opciones}
                            checked={estaSeleccionada} // Marcar el radio button si la opción está seleccionada
                            onChange={() => {}} // Necesario para React, aunque el cambio real se maneja en el onClick de la etiqueta
                            style={{ marginRight: "10px", cursor: "pointer" }}
                          />
                          <span className="truncate-span-examen"
                          style={{marginRight:'130px'}}>
                            {opcion.valor}
                          </span>
                        </label>
                      );
                    })}
                </div>
              </div>
            ) : (
              <p>
                <em>Lo sentimos, parece que no hay preguntas registradas</em>
              </p>
            )}
            <div>
              {preguntaActual > 0 && (
                <button
                  onClick={() =>
                    setPreguntaActual((prev) => Math.max(prev - 1, 0))
                  }
                  className="button_as"
                >
                  Anterior
                </button>
              )}

              {preguntaActual < examenPreguntas.length - 1 && (
                <button
                  onClick={() =>
                    setPreguntaActual((prev) =>
                      Math.min(prev + 1, examenPreguntas.length - 1)
                    )
                  }
                  className="button_as"
                >
                  Siguiente
                </button>
              )}
              <p>
                Pregunta {preguntaActual + 1} de {examenPreguntas.length}
              </p>
              <button
                onClick={handleSubmitExamen}
                className="btn btn-info"
                disabled={preguntaActual + 1 !== examenPreguntas.length}
              >
                Terminar Examen
              </button>
            </div>
          </main>
        </Container>
      </div>
    </Fragment>
  );
}

export default withRouter(ExamenResolution);
