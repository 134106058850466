import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { url } from "../../../../../config.js";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical,faMagnifyingGlass,faLock,faLockOpen } from '@fortawesome/free-solid-svg-icons'
import "./enrolamiento.css";

const AdminEnrolamiento = (props) => {
  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  };

  const baseUrl = url;
  const cookies = new Cookies();

  const [data, setData] = useState([]);
  const [idCurso, setIdCurso] = useState("");
  const [idEstado, setIdEstado] = useState(3);
  const [nombre, setNombre] = useState("");

  const [cursoOptions, setCursoOptions] = useState([]);
  const [estadoOptions, setEstadoOptions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    cargarOpciones();
    cargarEstados();
  }, []);

  const HandleBuscarCursos = async () => {
    if (!idCurso || !idEstado) {
      Swal.fire({
        icon: "error",
        title: "Oben Academy",
        text: "Debe seleccionar Curso y Estado para realizar la Búsqueda.",
      });
      return;
    }
    try {
      BuscarCursosXColaborador();
    } catch (error) {
      setData([]);
    }
  };

  const enrolarDesenrolarEstudianteCurso = async (
    estudiantecurso,
    colaborador,
    usuario,
    n_doc,
    curso,
    condicion
  ) => {
    try {
      const body = {
        IdEstudianteCurso: estudiantecurso,
        IdColaborador: colaborador,
        IdUsuario: usuario,
        NroDocumento: n_doc,
        IdCurso: curso,
        IdCondicion: condicion,
      };

      //console.log(body);
      const token = cookies.get('token');
      const result = await axios.post(
        `${baseUrl}Enrolamiento/Enrolar_Desenrolar_Estudiante_Curso`,
        body, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
      });

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarCursosXColaborador();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const BuscarCursosXColaborador = async () => {
    const body = {
      IdCurso: idCurso,
      NombreUsuario: nombre,
      IdCondicion: idEstado,
    };

    const token = cookies.get('token');
    const result = await axios.post(
      `${baseUrl}Enrolamiento/ObtenerColaboradorXCurso`,
      body, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
    });

    setData(result.data);
    setCurrentPage(1);
  };

  const cargarOpciones = async () => {
    const cursos = await axios.get(
      baseUrl + "Enrolamiento/Obtener_Cursos_Activos"
    );

    setCursoOptions(cursos.data);
    
  };

  const cargarEstados = async ()=>{
    const estados = [
      { id: 1, nombre: "Enrolados" },
      { id: 2, nombre: "No Enrolados" },
      { id: 3, nombre: "Todos" },
    ];

    setEstadoOptions(estados);
  }

  const handleCursoChange = (e) => {
    setIdCurso(e.target.value);
  };

  const handleEstadoChange = (e) => {
    setIdEstado(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Fragment>
      <div className="admin-enrolamiento-container">
        <h1 style={{ marginBottom: "20px", textAlign: "center" }}>
          Enrolamiento Cursos - Estudiantes
        </h1>

        {/* Filtros de Búsqueda */}
        <div className="search-section">
          <div className="search-filters">
            <div>
              <label htmlFor="curso-select">
                <b>Curso:</b>
              </label>
              <select
                id="curso-select"
                value={idCurso}
                onChange={handleCursoChange}
                className="filter-select"
                style={{ width: "300px" }}
              >
                <option value="">Seleccionar</option>
                {cursoOptions.map((curso) => (
                  <option key={curso.id_curso} value={curso.id_curso}>
                    {curso.nombre_curso}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="estado-select">
                <b>Estado:</b>
              </label>
              <select
                id="estado-select"
                value={idEstado}
                onChange={handleEstadoChange}
                className="filter-select"
                style={{ width: "165px" }}
              >
                <option value="">Seleccionar</option>
                {estadoOptions.map((estado) => (
                  <option key={estado.id} value={estado.id}>
                    {estado.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                id="nombre-input"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                placeholder="Ingrese Nombre"
                className="filter-select"
                style={{ width: "300px",marginLeft: "5px",borderRadius: "5px", }}
              />
            </div>
            <button
              onClick={HandleBuscarCursos}
              style={{
                padding: "5px 15px",
                backgroundColor: "#F07436",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <b><FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar</b>
            </button>
          </div>
        </div>

        <div
          className="table-responsive"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          <table className="table table-striped table-bordered table-light">
            <thead className="thead-dark">
              <tr>
                <th>Acciones</th>
                <th>Estudiante</th>
                <th>N° Documento</th>
                <th>Curso</th>
                <th>¿Usuario Activo?</th>
                <th>¿Enrolado al Curso?</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            backgroundColor: "#F07436",
                            color: "white",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          {!item.id_curso_estudiante && (
                            <button
                              class="dropdown-item"
                              type="button"
                              onClick={() =>
                                enrolarDesenrolarEstudianteCurso(
                                  item.id_curso_estudiante,
                                  item.id_colaborador,
                                  item.id_usuario,
                                  item.n_documento,
                                  item.id_curso,
                                  1
                                )
                              }
                            >
                              <b><FontAwesomeIcon icon={faLock}/>  Enrolar Curso</b>
                            </button>
                          )}

                          {item.id_curso_estudiante && (
                            <button
                              class="dropdown-item"
                              type="button"
                              onClick={() =>
                                enrolarDesenrolarEstudianteCurso(
                                  item.id_curso_estudiante,
                                  item.id_colaborador,
                                  item.id_usuario,
                                  item.n_documento,
                                  item.id_curso,
                                  2
                                )
                              }
                            >
                              <b><FontAwesomeIcon icon={faLockOpen}/>  Desenrolar Curso</b>
                            </button>
                          )}
                        </div>
                      </div>
                    </td>
                    <td>{item.nombre_completo}</td>
                    <td>{item.n_documento}</td>
                    <td><b>{item.curso}</b></td>
                    <td>{item.usuario_activo}</td>
                    <td>{item.esta_enrolado}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No hay datos disponibles
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary btn-sm"
          >
            Anterior
          </button>
          <span style={{ margin: "0 10px" }}>Página {currentPage}</span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= data.length}
            className="btn btn-secondary btn-sm"
          >
            Siguiente
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(AdminEnrolamiento);
