import React, { useState, useEffect, Component, Fragment, useRef } from "react";
import md5 from "md5";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "universal-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../../css/Styles.css";
import Images from "../../img/index.js";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { url } from "../../config";

function Register(props) {

  useEffect(() => {
    // Detectar la resolución del monitor
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    // Ajustar el zoom según la resolución
    if (screenWidth <= 1352 || screenHeight <= 768 || screenWidth <= 1366 || screenHeight <= 768) {
      document.body.style.zoom = '75%';
    }
  }, []);

  // Se defube la variable paises
  const [paises, setPaises] = useState([]);
  // Dirección localhost cambia dependiendo del dispositivo
  const baseUrl = url;
  const cookies = new Cookies();

  const [form, setForm] = useState({
    nro_documento: "",
    password: "",
    confirmPassword: "",

    nombres: "",
    apellidos: "",
    idPais: "",
    correo: "",
  });

  // Capturando datos para el usuario, colaborador y el usuario_rol
  const usuarioColaborador = {
    usuario: {
      nro_documento: form.nro_documento,
      password: md5(form.password),
    },
    colaborador: {
      nombres: form.nombres,
      apellidos: form.apellidos,
      nro_documento: form.nro_documento,
      correo: form.correo,
      idPais: Number.parseInt(form.idPais),
    },
    usuario_rol: {},
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  // Evitando el ingreso de letras en el campo nro_documento
  const [disabled, setDisableInput] = useState(false);
  const inputRef = useRef(null);

  const handleN = (e) => {
    const charCode = e.charCode;
  
    if (
      (charCode <= 57 && charCode >= 48) ||
      charCode === 46 ||                    
      charCode === 45                      
    ) {
    } else {
      setDisableInput(true);
      inputRef.current.blur();
      setTimeout(enableInput, 100);
    }
  };
  
  const enableInput = () => {
    setDisableInput(false);
    inputRef.current.focus();
  };

  // Mostrar y ocultar contraseña
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //Validar si es correo:
  const esCorreoValido = (correo) => {
    const expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(correo);
  };
  // Registrar usuario
  const registrarUsuario = async () => {
    if (!esCorreoValido(usuarioColaborador.colaborador.correo)) {
      Swal.fire({
        icon: 'error',
        title: 'Correo inválido',
        text: 'Por favor, ingresa una dirección de correo electrónico válida.',
      });
      return;
    }
  
    try {
      //
      //
      //
      // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
      if (!form.nro_documento || !form.password || !form.confirmPassword) {
        throw new Error("Por favor, complete todos los campos.");
      }
      // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
      if (!form.nombres || !form.apellidos || !form.idPais || !form.correo) {
        throw new Error("Por favor, complete todos los campos.");
      }
      // VALIDAR CONFIRMACIÓN DE CONTRASEÑA
      if (form.password !== form.confirmPassword) {
        throw new Error("Las contraseñas no coinciden.");
      }
      //
      // REGISTRAR USUARIO Y COLABORADOR
      const response = await axios.post(
        baseUrl + "usuarios/registrarUsuarioYColaborador/",
        usuarioColaborador
      );
      //
      //
      //
      // MENSAJE DE ÉXITO
      if (response.status === 201) {
        Swal.fire({
          icon: "success",
          title: "Registro exitoso",
          text: "Inicia Sesión!",
          showConfirmButton: true,
          confirmButtonText: "Ok",
        });
        props.history.push("/"); // Redirecciona a la vista de login
      }
    } catch (error) {
      // MANEJO DE ERRORES
      if (error.message === "Por favor, complete todos los campos.") {
        Swal.fire({
          icon: "error",
          title: "Campos incompletos!",
          text: error.message,
        });
      } else if (error.message === "Las contraseñas no coinciden.") {
        Swal.fire({
          icon: "error",
          title: "Datos incorrectos",
          text: error.message,
        });
      } else if (error.response && error.response.status === 400) {
        if (
          error.response.data.mensaje ===
          "El correo que ha ingresado ya está registrado."
        ) {
          Swal.fire({
            icon: "error",
            title: "Correo existente",
            text: error.response.data.mensaje,
          });
        } else if (
          error.response.data.mensaje ===
          "El número de documento que ha ingresado ya está registrado."
        ) {
          Swal.fire({
            icon: "error",
            title: "Número de documento existente",
            text: error.response.data.mensaje,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Lo sentimos!",
            text: error.response.data,
          });
        }
      } else if (error.response && error.response.status === 409) {
        Swal.fire({
          icon: "error",
          title: "Usuario ya existe",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Lo sentimos!",
          text: error.message,
        });
      }
    }
  };

  // Validación con idUsuario
  useEffect(() => {
    if (cookies.get("token")) {
      props.history.push("/menu");
    }
  }, []);

  // Recorrer la tabla paises para mostrar en el formulario
  useEffect(() => {
    const fetchPaises = async () => {
      const result = await axios.get(baseUrl + "pais");
      setPaises(result.data);
    };
    fetchPaises();
  }, []);

  return (
    <div className="grid-container">      
      <div className="bodyy"></div>
      <div class="rectangle">
        <div className="grid-item form-container">
          <div className="form-wrapper">
            <div className="myform form ">
              <div className="logo mb-3"></div>
              <form>
                <div className="form-group">
                  <img
                    className="acad"
                    src={require("../../img/logo.png")}
                    alt="logo"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresa tus nombres"
                    name="nombres"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ingresa tus apellidos"
                    name="apellidos"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label className="elpais" htmlFor="idPais">
                    Elija un País:{" "}
                  </label>
                  <select
                    className="idPais"
                    id="idPais"
                    name="idPais"
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Paises:
                    </option>
                    {paises.map((pais) => (
                      <option value={pais.idPais} key={pais.idPais}>
                        {pais.nom_pais}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Ingresa tu correo corporativo o personal"
                    name="correo"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    id="nro_documento"
                    type="text"
                    ref={inputRef}
                    disabled={disabled}
                    maxlength="15"
                    className="form-control"
                    placeholder="Ingresa tu documento de identidad"
                    name="nro_documento"
                    onChange={handleChange}
                    onKeyPress={handleN}
                    required
                  />
                </div>
                <div className="form-group password-input">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      maxlength="15"
                      className="form-control"
                      placeholder="Ingresa tu contraseña"
                      name="password"
                      onChange={handleChange}
                      required
                    />
                    <submit onClick={togglePasswordVisibility}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </submit>
                  </div>
                </div>
                <div className="form-group password-input">
                  {" "}
                  {/* Confirmar la contraseña */}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      maxlength="15"
                      className="form-control"
                      placeholder="Vuelve a ingresar tu contraseña"
                      name="confirmPassword"
                      onChange={handleChange}
                      required
                    />
                    <submit onClick={togglePasswordVisibility}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </submit>
                  </div>
                </div>
                <div className="col-md-12 text-center ">
                <submit
                    className="naranjabtn btn btn-block mybtn btn-success tx-tfm"
                    onClick={registrarUsuario}
                  >
                    Registrarse
                  </submit>
                </div>
                <div className="col-md-12 ">
                  <div className="login-or">
                    <hr className="hr-or"></hr>
                    <span className="span-or">O</span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <p class="text-center">
                    ¿Ya tienes una cuenta?{" "}
                    <a href="" id="login">
                      <Link to={"/"} className="link-style">Inicia sesión aqui</Link>

                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="image-wrapper">
            <img
              className="logo"
              src={require("../../img/logo_multic2.png")}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
