import React, { Fragment, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { url } from "../../../../../config.js";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faMagnifyingGlass,
  faLock,
  faLockOpen,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import "./enrolamiento.css";

const AdminControlExamen = (props) => {
  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  };

  const baseUrl = url;
  const cookies = new Cookies();

  const [data, setData] = useState([]);
  const [idCurso, setIdCurso] = useState("");
  const [idEstado, setIdEstado] = useState("");
  const [nombre, setNombre] = useState("");

  const [cursoOptions, setCursoOptions] = useState([]);
  const [estadoOptions, setEstadoOptions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    cargarOpciones();
  }, []);

  const HandleBuscarRegistrosRespuestas = async () => {
    if (!idCurso) {
      Swal.fire({
        icon: "error",
        title: "Oben Academy",
        text: "Debe seleccionar Curso para realizar la Búsqueda.",
      });
      return;
    }
    try {
      BuscarRegistrosRespuestas();
    } catch (error) {
      setData([]);
    }
  };

  const BuscarRegistrosRespuestas = async () => {
    const body = {
      IdCurso: idCurso,
      NombreUsuario: nombre,
      IdCondicion: 1,
    };

    const token = cookies.get("token");

    const result = await axios.post(
      `${baseUrl}Enrolamiento/ObtenerRegistrosRespuestasNotas`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setData(result.data);
    setCurrentPage(1);
  };

  const cargarOpciones = async () => {
    const cursos = await axios.get(
      baseUrl + "Enrolamiento/Obtener_Cursos_Activos"
    );

    setCursoOptions(cursos.data);
  };

  const handleCursoChange = (e) => {
    setIdCurso(e.target.value);
  };

  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const ResetearIntentosExamen = async (
    id_ex_respuesta
  ) => {
    try {
      const body = {
        IdExamenRespuesta: id_ex_respuesta
      };


      const token = cookies.get('token');
      const result = await axios.post(
        `${baseUrl}Enrolamiento/ResetearIntentosExamen`,
        body, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
      });

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarRegistrosRespuestas();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  return (
    <Fragment>
      <div className="admin-enrolamiento-container">
        <h1 style={{ marginBottom: "20px", textAlign: "center" }}>
          Control Exámenes
        </h1>

        {/* Filtros de Búsqueda */}
        <div className="search-section">
          <div className="search-filters">
            <div>
              <label htmlFor="curso-select">
                <b>Curso:</b>
              </label>
              <select
                id="curso-select"
                value={idCurso}
                onChange={handleCursoChange}
                className="filter-select"
                style={{ width: "450px" }}
              >
                <option value="">Seleccionar</option>
                {cursoOptions.map((curso) => (
                  <option key={curso.id_curso} value={curso.id_curso}>
                    {curso.nombre_curso}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                id="nombre-input"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                placeholder="Ingrese Nombre"
                className="filter-select"
                style={{
                  width: "300px",
                  marginLeft: "5px",
                  borderRadius: "5px",
                }}
              />
            </div>
            <button
              onClick={HandleBuscarRegistrosRespuestas}
              style={{
                padding: "5px 15px",
                backgroundColor: "#F07436",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <b>
                <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar
              </b>
            </button>
          </div>
        </div>

        <div
          className="table-responsive"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          <table className="table table-striped table-bordered table-light">
            <thead className="thead-dark">
              <tr>
                <th>Acciones</th>
                <th>Estudiante</th>
                <th>N° Documento</th>
                <th>Curso</th>
                <th>Nota Examen</th>
                <th>N° Intentos</th>
                <th>N° Intentos Realizados</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            backgroundColor: "#F07436",
                            color: "white",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <button class="dropdown-item" type="button"
                          onClick={() =>
                            ResetearIntentosExamen(
                              item.id_examen_respuesta
                            )
                          }>
                            <b>
                              <FontAwesomeIcon icon={faRotateRight} /> Resetear
                              Intentos
                            </b>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>{item.nombre_completo}</td>
                    <td>{item.n_documento}</td>
                    <td>
                      <b>{item.curso}</b>
                    </td>
                    <td>{item.nota_examen}</td>
                    <td>{item.cantidad_intentos}</td>
                    <td>
                      <b>{item.intentos_realizados}</b>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No hay datos disponibles
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary btn-sm"
          >
            Anterior
          </button>
          <span style={{ margin: "0 10px" }}>Página {currentPage}</span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= data.length}
            className="btn btn-secondary btn-sm"
          >
            Siguiente
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminControlExamen;
