import React, { Fragment, useEffect, useState, useRef } from "react";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { url } from "../../../../../config.js";
import SessionManager from "../../../../SessionManagement.js";
import CerrarSesion from "../../../../CerrarSesion.js";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faMagnifyingGlass,
  faLock,
  faLockOpen,
  faRotateRight,
  faPlus,
  faSchool,
  faChalkboardUser,
  faUserSecret,
  faUser,
  faToggleOn,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FaWindowClose } from "react-icons/fa";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { CSSTransition } from "react-transition-group";
import "./enrolamiento.css";
import "./PopUp.css";

const AdminControlUsuarios = (props) => {
  SessionManager(props);

  const cerrarSesion = () => {
    CerrarSesion(props);
  };

  const baseUrl = url;
  const cookies = new Cookies();

  const [data, setData] = useState([]);
  const [idCurso, setIdCurso] = useState("");
  const [idEstado, setIdEstado] = useState(3);
  const [idPais, setIdPais] = useState(null);
  const [nombre, setNombre] = useState("");

  const [estadoOptions, setEstadoOptions] = useState([]);
  const [paisesOptions, setPaisesOptions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  /****/
  const [FormRegister, setFormRegister] = useState({
    NombreUsuario: "",
    ApellidosUsuario: "",
    CorreoUsuario: "",
    DniUsuario: "",
  });
  const [PaisUsuario, setPaisUsuario] = useState(null);
  /*****/
  const [IdUsuario, setIdUsuario] = useState(null);
  const [IdColaborador, setIdColaborador] = useState(null);
  const [FormEdit, setFormEdit] = useState({
    EditNombreUsuario: "",
    EditApellidosUsuario: "",
    EditCorreoUsuario: "",
    EditDniUsuario: "",
  });
  const [EditPaisUsuario, setEditPaisUsuario] = useState(null);
  /***/

  const [showModalRegistrarUsuario, setShowModalRegistrarUsuario] =
    useState(false);
  const [showModalEditarUsuario, setShowModalEditarUsuario] = useState(false);

  const AbrirModalRegistroUsuario = () => {
    setShowModalRegistrarUsuario(true);
  };
  const CerrarModalRegistroUsuario = () => {
    setShowModalRegistrarUsuario(false);
    setPaisUsuario(null);
    setFormRegister({
      NombreUsuario: "",
      ApellidosUsuario: "",
      CorreoUsuario: "",
      DniUsuario: "",
    });
  };

  const AbrirModalEditarUsuario = () => {
    setShowModalEditarUsuario(true);
  };
  const CerrarModalEditarUsuario = () => {
    setShowModalEditarUsuario(false);
    setIdUsuario(null);
    setIdColaborador(null);
    setEditPaisUsuario(null);
    setFormEdit({
      EditNombreUsuario: "",
      EditApellidosUsuario: "",
      EditCorreoUsuario: "",
      EditDniUsuario: "",
    });
  };

  // Evitando el ingreso de letras en el campo nro_documento
  const [disabled, setDisableInput] = useState(false);
  const inputRef = useRef(null);

  const handleN = (e) => {
    const charCode = e.charCode;

    if (
      (charCode <= 57 && charCode >= 48) ||
      charCode === 46 ||
      charCode === 45
    ) {
    } else {
      setDisableInput(true);
      inputRef.current.blur();
      setTimeout(enableInput, 100);
    }
  };

  const enableInput = () => {
    setDisableInput(false);
    inputRef.current.focus();
  };

  useEffect(() => {
    cargarEstados();
    cargarPaises();
  }, []);

  const HandleBuscarColaboradores = async () => {
    if (!idEstado) {
      Swal.fire({
        icon: "error",
        title: "Oben Academy",
        text: "Debe seleccionar Estado para realizar la Búsqueda.",
      });
      return;
    }
    try {
      BuscarColaboradores();
    } catch (error) {
      setData([]);
    }
  };

  const BuscarColaboradores = async () => {
    const body = {
      NombreUsuario: nombre,
      IdPais: idPais === "" ? null : idPais,
      IdCondicion: idEstado,
    };

    const token = cookies.get("token");

    const result = await axios.post(
      `${baseUrl}Enrolamiento/Obtener_Colaboradores_ControlUsuarios`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setData(result.data);
    setCurrentPage(1);
    //console.log(result.data);
  };

  const cargarEstados = async () => {
    const estados = [
      { id: 1, nombre: "Activos" },
      { id: 2, nombre: "Inactivos" },
      { id: 3, nombre: "Todos" },
    ];

    setEstadoOptions(estados);
  };

  const cargarPaises = async () => {
    const paises = await axios.get(baseUrl + "Enrolamiento/Obtener_Paises");

    setPaisesOptions(paises.data);
  };

  const HandleActivarDesactivarUsuario = async (id, ndoc) => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas Actualizar el Estado del Usuario Seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await ActivarDesactivarUsuario(id, ndoc);
    } else {
      return;
    }
  };

  const ActivarDesactivarUsuario = async (id, ndoc) => {
    try {
      const body = {
        IdUsuario: id,
        NroDocumento: ndoc,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/ActivarDesactivarUsuarioControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarColaboradores();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const HandleAsignarQuitarRolDocente = async (idusurol, idusu) => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas Actualizar el Rol Docente del Usuario Seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await AsignarQuitarRolDocente(idusurol, idusu);
    } else {
      return;
    }
  };

  const AsignarQuitarRolDocente = async (idusurol, idusu) => {
    try {
      const body = {
        IdUsuarioRol: idusurol,
        IdUsuario: idusu,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/Asignar_Quitar_RolDocente_ControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarColaboradores();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const HandleAsignarQuitarRolAdmin = async (idusurol, idusu) => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas Actualizar el Rol Administrador del Usuario Seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await AsignarQuitarRolAdmin(idusurol, idusu);
    } else {
      return;
    }
  };

  const AsignarQuitarRolAdmin = async (idusurol, idusu) => {
    try {
      const body = {
        IdUsuarioRol: idusurol,
        IdUsuario: idusu,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/Asignar_Quitar_RolAdministrador_ControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarColaboradores();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const HandleAsignarRolEstudiante = async (idusurol, idusu) => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas Actualizar el Rol Estudiante del Usuario Seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await AsignarRolEstudiante(idusurol, idusu);
    } else {
      return;
    }
  };

  const AsignarRolEstudiante = async (idusurol, idusu) => {
    try {
      const body = {
        IdUsuarioRol: idusurol,
        IdUsuario: idusu,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/Asignar_RolEstudiante_ControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarColaboradores();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const HandleAsignarQuitarRolAdminFuncional = async (idusurol, idusu) => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas Actualizar el Rol Administrador Funcional del Usuario Seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await AsignarQuitarRolAdminFuncional(idusurol, idusu);
    } else {
      return;
    }
  };

  const AsignarQuitarRolAdminFuncional = async (idusurol, idusu) => {
    try {
      const body = {
        IdUsuarioRol: idusurol,
        IdUsuario: idusu,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/Asignar_Quitar_RolAdministradorFuncional_ControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarColaboradores();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const HandleResetearContrasena = async (idusu) => {
    const result = await Swal.fire({
      title: "Oben Academy",
      text: "¿Deseas Resetear la Contraseña del Usuario Seleccionado?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      await ResetearContrasena(idusu);
    } else {
      return;
    }
  };

  const ResetearContrasena = async (idusu) => {
    try {
      const body = {
        IdUsuario: idusu,
      };

      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/ResetearContrasena_ControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        });
        BuscarColaboradores();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const handleEstadoChange = (e) => {
    setIdEstado(e.target.value);
  };

  const handlePaisChange = (e) => {
    setIdPais(e.target.value);
  };

  const handlePais_RegistroUsuarioChange = (e) => {
    setPaisUsuario(e.target.value);
  };

  const handlePais_EditarUsuarioChange = (e) => {
    setEditPaisUsuario(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const esCorreoValido = (correo) => {
    const expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(correo);
  };

  const handleChangeRegisterValues = (e) => {
    const { name, value } = e.target;
    setFormRegister({
      ...FormRegister,
      [name]: value,
    });
  };
  const handleChangeEditValues = (e) => {
    const { name, value } = e.target;
    setFormEdit({
      ...FormEdit,
      [name]: value,
    });
  };

  const HandleRegistrarUsuario = async () => {
    if (
      !FormRegister.NombreUsuario ||
      !FormRegister.ApellidosUsuario ||
      !FormRegister.CorreoUsuario ||
      !FormRegister.DniUsuario ||
      PaisUsuario == null
    ) {
      Swal.fire({
        icon: "error",
        title: "Oben Academy",
        text: "Debes Completar todos los campos para realizar el Registro de Usuario.",
      });
      return;
    }
    if (!esCorreoValido(FormRegister.CorreoUsuario)) {
      Swal.fire({
        icon: "error",
        title: "Oben Academy",
        text: "Por favor, ingresa una dirección de correo electrónico válida.",
      });
      return;
    }
    try {
      RegistrarUsuario();
      //BuscarCursosXColaborador();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const RegistrarUsuario = async () => {
    try {
      const body = {
        Nombres: FormRegister.NombreUsuario,
        Apellidos: FormRegister.ApellidosUsuario,
        IdPais: PaisUsuario,
        Correo: FormRegister.CorreoUsuario.trim(),
        Dni: FormRegister.DniUsuario,
      };
      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/CrearUsuarioControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        }).then(() => {
          if (estadoMensaje === "warning") {
            return;
          }
          CerrarModalRegistroUsuario();
          BuscarColaboradores();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
      /*const body = {
        Nombres: FormEdit.EditNombreUsuario,
        Apellidos: FormEdit.EditApellidosUsuario,
        IdPais: EditPaisUsuario,
        Correo: FormEdit.EditCorreoUsuario,
        Dni: FormEdit.EditDniUsuario,
      };*/
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const LoadUsuarioData = async (usuario,colaborador,nombres,apellidos,correo,idpais,dni) =>{
    setIdUsuario(usuario);
    setIdColaborador(colaborador);
    setFormEdit({
      EditNombreUsuario: nombres,
      EditApellidosUsuario: apellidos,
      EditCorreoUsuario: correo,
      EditDniUsuario: dni,
    });
    setEditPaisUsuario(idpais);
  }

  const HandleEditarUsuario = async () => {
    if (
      IdUsuario==null||
      IdColaborador==null||
      !FormEdit.EditNombreUsuario ||
      !FormEdit.EditApellidosUsuario ||
      !FormEdit.EditCorreoUsuario ||
      !FormEdit.EditDniUsuario ||
      EditPaisUsuario == null
    ) {
      Swal.fire({
        icon: "error",
        title: "Oben Academy",
        text: "Debes Completar todos los campos para Actualizar el Registro.",
      });
      return;
    }
    if (!esCorreoValido(FormEdit.EditCorreoUsuario)) {
      Swal.fire({
        icon: "error",
        title: "Oben Academy",
        text: "Por favor, ingresa una dirección de correo electrónico válida.",
      });
      return;
    }
    try {
      EditarUsuario();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  const EditarUsuario = async () => {
    try {
      const body = {
        IdUsuario:IdUsuario,
        IdColaborador:IdColaborador,
        Nombres: FormEdit.EditNombreUsuario,
        Apellidos: FormEdit.EditApellidosUsuario,
        IdPais: EditPaisUsuario,
        Correo: FormEdit.EditCorreoUsuario.trim(),
        Dni: FormEdit.EditDniUsuario,
      };
      const token = cookies.get("token");
      const result = await axios.post(
        `${baseUrl}Enrolamiento/EditarUsuarioControlUsuarios`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.message) {
        const estadoMensaje = result.data.message_status;
        Swal.fire({
          icon: estadoMensaje,
          title: "Oben Academy",
          text: result.data.message,
        }).then(() => {
          if (estadoMensaje === "warning") {
            return;
          }
          CerrarModalEditarUsuario();
          BuscarColaboradores();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ocurrió un error..",
        });
      }
      /*const body = {
        Nombres: FormEdit.EditNombreUsuario,
        Apellidos: FormEdit.EditApellidosUsuario,
        IdPais: EditPaisUsuario,
        Correo: FormEdit.EditCorreoUsuario,
        Dni: FormEdit.EditDniUsuario,
      };*/
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Ocurrió un error.. \n" + error,
      });
    }
  };

  return (
    <Fragment>
      <div className="admin-enrolamiento-container">
        <h1 style={{ marginBottom: "20px", textAlign: "center" }}>
          Control Usuarios
        </h1>

        {/* Filtros de Búsqueda */}
        <div className="search-section">
          <div className="search-filters">
            <div>
              <label htmlFor="estado-select">
                <b>Estado:</b>
              </label>
              <select
                id="estado-select"
                value={idEstado}
                onChange={handleEstadoChange}
                className="filter-select"
                style={{ width: "165px" }}
              >
                <option value="">Seleccionar</option>
                {estadoOptions.map((estado) => (
                  <option key={estado.id} value={estado.id}>
                    {estado.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="curso-select">
                <b>País:</b>
              </label>
              <select
                id="curso-select"
                value={idPais}
                onChange={handlePaisChange}
                className="filter-select"
                style={{ width: "200px" }}
              >
                <option value="">Seleccionar</option>
                {paisesOptions.map((pais) => (
                  <option key={pais.id_pais} value={pais.id_pais}>
                    {pais.nombre_pais}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                id="nombre-input"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                placeholder="Ingrese Nombre"
                className="filter-select"
                style={{
                  width: "300px",
                  marginLeft: "5px",
                  borderRadius: "5px",
                }}
              />
            </div>
            <button
              onClick={HandleBuscarColaboradores}
              style={{
                padding: "5px 15px",
                backgroundColor: "#F07436",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <b>
                <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar
              </b>
            </button>
            <button
              //onClick={HandleBuscarColaboradores}
              type="button"
              className="btn btn-success"
              onClick={AbrirModalRegistroUsuario}
            >
              <b>
                <FontAwesomeIcon icon={faPlus} /> Añadir Nuevo Usuario
              </b>
            </button>
          </div>
        </div>

        <div
          className="table-responsive"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          <table className="table table-striped table-bordered table-light">
            <thead className="thead-dark">
              <tr>
                <th>Acciones</th>
                <th>Estudiante</th>
                <th>N° Documento</th>
                <th>País</th>
                <th>¿Tiene Rol Estudiante?</th>
                <th>¿Tiene Rol Docente?</th>
                <th>¿Tiene Rol Admin?</th>
                <th>¿Tiene Rol Admin Funcional?</th>
                <th>¿Está Activo?</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            backgroundColor: "#F07436",
                            color: "white",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faEllipsisVertical} />
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                          style={{
                            width: "auto",
                            padding: "0.5rem",
                            fontSize: "0.8rem",
                          }}
                        >
                          {!(
                            item.id_rol_estudiante || item.es_activo !== "SI"
                          ) && (
                            <button
                              className="dropdown-item btn-sm"
                              type="button"
                              onClick={() =>
                                HandleAsignarRolEstudiante(
                                  item.id_rol_estudiante,
                                  item.id_usuario
                                )
                              }
                            >
                              <b>
                                <FontAwesomeIcon icon={faSchool} /> Enrolar
                                Estudiante
                              </b>
                            </button>
                          )}
                          <button
                            class="dropdown-item btn-sm"
                            type="button"
                            disabled={item.es_activo !== "SI"}
                            onClick={() =>
                              HandleAsignarQuitarRolDocente(
                                item.id_rol_docente,
                                item.id_usuario
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faChalkboardUser} />
                              Enrolar/Desenrolar Docente
                            </b>
                          </button>
                          <button
                            class="dropdown-item btn-sm"
                            type="button"
                            disabled={item.es_activo !== "SI"}
                            onClick={() =>
                              HandleAsignarQuitarRolAdmin(
                                item.id_rol_admin,
                                item.id_usuario
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faUserSecret} />
                              Enrolar/Desenrolar Administrador
                            </b>
                          </button>
                          <button
                            class="dropdown-item btn-sm"
                            type="button"
                            disabled={item.es_activo !== "SI"}
                            onClick={() =>
                              HandleAsignarQuitarRolAdminFuncional(
                                item.id_rol_admin_funcional,
                                item.id_usuario
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faUserSecret} />
                              Enrolar/Desenrolar Admin Funcional
                            </b>
                          </button>
                          <button
                            class="dropdown-item btn btn-info btn-sm"
                            type="button"
                            disabled={item.es_activo !== "SI"}
                            onClick={() =>
                              HandleResetearContrasena(item.id_usuario)
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faUser} /> Resetear
                              Contraseña
                            </b>
                          </button>
                          <button
                            class="dropdown-item btn btn-warning btn-sm"
                            type="button"
                            style={{ background: "#dc3545", color: "white" }}
                            onClick={() =>
                              HandleActivarDesactivarUsuario(
                                item.id_usuario,
                                item.n_documento
                              )
                            }
                          >
                            <b>
                              <FontAwesomeIcon icon={faToggleOn} />{" "}
                              Activar/Desactivar Usuario
                            </b>
                          </button>
                          <button
                            class="dropdown-item btn btn-warning btn-sm"
                            type="button"
                            disabled={item.es_activo !== "SI"}
                            onClick={()=>{
                              LoadUsuarioData(item.id_usuario,item.id_colaborador,item.nombres,item.apellidos,item.correo,item.id_pais,item.n_documento);
                              AbrirModalEditarUsuario();

                            }}
                          >
                            <b>
                              <FontAwesomeIcon icon={faUser} /> Editar Usuario
                            </b>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>{item.nombre_completo}</td>
                    <td>{item.n_documento}</td>
                    <td>{item.pais}</td>
                    <td>
                      <b>{item.es_estudiante}</b>
                    </td>
                    <td>
                      <b>{item.es_docente}</b>
                    </td>
                    <td>
                      <b>{item.es_admin}</b>
                    </td>
                    <td>
                      <b>{item.es_admin_funcional}</b>
                    </td>
                    <td>
                      <b style={{ color: "red" }}>{item.es_activo}</b>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No hay datos disponibles
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="btn btn-secondary btn-sm"
          >
            Anterior
          </button>
          <span style={{ margin: "0 10px" }}>Página {currentPage}</span>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= data.length}
            className="btn btn-secondary btn-sm"
          >
            Siguiente
          </button>
        </div>

        <CSSTransition
          in={showModalRegistrarUsuario}
          timeout={300}
          classNames="popup-transition"
          unmountOnExit
        >
          <Popup
            open={showModalRegistrarUsuario}
            onClose={CerrarModalRegistroUsuario}
            closeOnDocumentClick={false}
            closeOnEscape={true}
            className="popup-content"
          >
            <div className="popup-wrapper">
              <div className="popup-header">
                <div className="popup-title">Mantenimiento Usuarios</div>
                <div className="popup-close-button">
                  <FaWindowClose
                    color="#c82333"
                    className="invert-icon"
                    onClick={CerrarModalRegistroUsuario}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <form>
                <div>
                  <label className="modal-label" htmlFor="nombre">
                    <b>Nombres</b>
                  </label>
                  <input
                    id="NombreUsuario"
                    type="text"
                    name="NombreUsuario"
                    placeholder="Ingresa Nombres"
                    onChange={handleChangeRegisterValues}
                  />
                </div>
                <div>
                  <label className="modal-label" htmlFor="apellido">
                    <b>Apellidos</b>
                  </label>
                  <input
                    id="ApellidosUsuario"
                    type="text"
                    name="ApellidosUsuario"
                    placeholder="Ingresa Apellidos"
                    onChange={handleChangeRegisterValues}
                  />
                </div>
                <div>
                  <label className="modal-label" htmlFor="pais">
                    <b>País</b>
                  </label>
                  <select
                    id="curso-select"
                    value={PaisUsuario}
                    onChange={handlePais_RegistroUsuarioChange}
                    className="filter-select"
                  >
                    <option value="">Seleccionar</option>
                    {paisesOptions.map((pais) => (
                      <option key={pais.id_pais} value={pais.id_pais}>
                        {pais.nombre_pais}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="modal-label" htmlFor="email">
                    <b>Correo Electrónico</b>
                  </label>
                  <input
                    id="CorreoUsuario"
                    type="email"
                    name="CorreoUsuario"
                    placeholder="Ingresa Correo Electrónico"
                    onChange={handleChangeRegisterValues}
                  />
                </div>
                <div>
                  <label className="modal-label" htmlFor="dni">
                    <b>N° Documento</b>
                  </label>
                  <input
                    id="DniUsuario"
                    type="text"
                    name="DniUsuario"
                    ref={inputRef}
                    disabled={disabled}
                    placeholder="Ingresa DNI"
                    maxlength="15"
                    onKeyPress={handleN}
                    onChange={handleChangeRegisterValues}
                  />
                </div>
                <submit
                  className="btn btn-info"
                  onClick={HandleRegistrarUsuario}
                >
                  Registrarse
                </submit>
              </form>
            </div>
          </Popup>
        </CSSTransition>
        <CSSTransition
          in={showModalEditarUsuario}
          timeout={300}
          classNames="popup-transition"
          unmountOnExit
        >
          <Popup
            open={showModalEditarUsuario}
            onClose={CerrarModalEditarUsuario}
            closeOnDocumentClick={false}
            closeOnEscape={true}
            className="popup-content"
          >
            <div className="popup-wrapper">
              <div className="popup-header">
                <div className="popup-title">Editar Usuarios</div>
                <div className="popup-close-button">
                  <FaWindowClose
                    color="#c82333"
                    className="invert-icon"
                    onClick={CerrarModalEditarUsuario}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <form>
                <div>
                  <label className="modal-label" htmlFor="nombre">
                    <b>Nombres</b>
                  </label>
                  <input
                    id="EditNombreUsuario"
                    type="text"
                    value={FormEdit.EditNombreUsuario}
                    name="EditNombreUsuario"
                    placeholder="Ingresa Nombres"
                    onChange={handleChangeEditValues}
                  />
                </div>
                <div>
                  <label className="modal-label" htmlFor="apellido">
                    <b>Apellidos</b>
                  </label>
                  <input
                    id="EditApellidosUsuario"
                    value={FormEdit.EditApellidosUsuario}
                    type="text"
                    name="EditApellidosUsuario"
                    placeholder="Ingresa Apellidos"
                    onChange={handleChangeEditValues}
                  />
                </div>
                <div>
                  <label className="modal-label" htmlFor="pais">
                    <b>País</b>
                  </label>
                  <select
                    id="curso-select"
                    value={EditPaisUsuario}
                    onChange={handlePais_EditarUsuarioChange}
                    className="filter-select"
                  >
                    <option value="">Seleccionar</option>
                    {paisesOptions.map((pais) => (
                      <option key={pais.id_pais} value={pais.id_pais}>
                        {pais.nombre_pais}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="modal-label" htmlFor="email">
                    <b>Correo Electrónico</b>
                  </label>
                  <input
                    id="EditCorreoUsuario"
                    type="email"
                    value={FormEdit.EditCorreoUsuario}
                    name="EditCorreoUsuario"
                    placeholder="Ingresa Correo Electrónico"
                    onChange={handleChangeEditValues}
                  />
                </div>
                <div>
                  <label className="modal-label" htmlFor="dni">
                    <b>N° Documento</b>
                  </label>
                  <input
                    id="EditDniUsuario"
                    type="text"
                    name="EditDniUsuario"
                    value={FormEdit.EditDniUsuario}
                    ref={inputRef}
                    disabled={disabled}
                    placeholder="Ingresa DNI"
                    maxlength="15"
                    onKeyPress={handleN}
                    onChange={handleChangeEditValues}
                  />
                </div>
                <submit className="btn btn-info" onClick={HandleEditarUsuario}>
                  Guardar
                </submit>
              </form>
            </div>
          </Popup>
        </CSSTransition>
      </div>
    </Fragment>
  );
};

export default AdminControlUsuarios;
