import React, { useState, useEffect, Component, Fragment, useRef } from "react";
import md5 from "md5";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from "universal-cookie";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import "../../css/Styles.css";
import Images from "../../img/index.js";
import Swal from "sweetalert2";
import Popup from "reactjs-popup";
// import "reactjs-popup/dist/index.css";
import { FaEye, FaEyeSlash, FaWindowClose } from "react-icons/fa";
import jwt_decode from "jwt-decode";
import "./logingcss.css";
import { url } from "../../config.js";

function Login(props) {

  useEffect(() => {
    // Detectar la resolución del monitor
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    // Ajustar el zoom según la resolución
    if (screenWidth <= 1352 || screenHeight <= 768 || screenWidth <= 1366 || screenHeight <= 768) {
      document.body.style.zoom = '75%';
    }
  }, []);
  
  // Dirección localhost cambia dependiendo del dispositivo
  // // const baseUrl = "https://localhost:44322/api/";
  const baseUrl = url;
  const cookies = new Cookies();

  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);

  const handleForgotPasswordClick = () => {
    setShowForgotPasswordPopup(true);
  };

  const handleForgotPasswordClose = () => {
    setShowForgotPasswordPopup(false);
  };

  const [form, setForm] = useState({
    nro_documento: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  // Evitando el ingreso de letras en el campo nro_documento
  const [disabled, setDisableInput] = useState(false);
  const inputRef = useRef(null);

  const handleN = (e) => {
    const charCode = e.charCode;
  
    if (
      (charCode <= 57 && charCode >= 48) ||
      charCode === 46 ||                    
      charCode === 45                      
    ) {
    } else {
      setDisableInput(true);
      inputRef.current.blur();
      setTimeout(enableInput, 100);
    }
  };
  
  const enableInput = () => {
    setDisableInput(false);
    inputRef.current.focus();
  };
  

  // Mostrar y ocultar contraseña
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Enviar correo de olvidé contraseña
  const enviarCorreo = async (event) => {
    event.preventDefault();
    try {
      //
      //
      //
      // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
      if (!form.nro_documento) {
        throw new Error("Por favor, complete todos los campos.");
      }
      //
      //
      const documento = {
        nro_documento: form.nro_documento,
      };
      const response = await axios.post(
        baseUrl + "configuracion_correo/sendemail/",
        documento
      );
      //
      //
      if (response.data) {
        //const respuesta = response.data;
        //console.log(respuesta);
        Swal.fire("Correo Enviado!", "", "success");
        props.history.push("/");
      }
    } catch (error) {
      if (error.message === "Por favor, complete todos los campos.") {
        Swal.fire({
          icon: "error",
          title: "Campos incompletos!",
          text: error.message,
        });
      } else if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Número de documento no encontrado",
          text: "Inténtalo nuevamente o regístrate!",
          footer:
            '<a href="/register">¿Aún no tienes una cuenta? Regístrate aquí</a>',
        });
      } else {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Ha ocurrido un error",
          text: "Inténtalo nuevamente más tarde!",
        });
      }
    }
  };

  // Iniciar sesión
const iniciarSesion = async (event) => {
  event.preventDefault();

  try {
    // VALIDAR QUE EL FORMULARIO ESTÉ COMPLETO
    if (!form.nro_documento || !form.password) {
      throw new Error("Por favor, complete todos los campos.");
    }

    const response = await axios.post(baseUrl + "usuarios/login/", {
      nro_documento: form.nro_documento,
      password: md5(form.password),
    });

    if (response.data) {
      const token = response.data; // El token JWT recibido desde el backend
      cookies.set("token", token, { path: "/" });

      Swal.fire("Bienvenido a Oben Academy!", "", "success");
      props.history.push("/menu");
    }
  } catch (error) {
    console.log("Axios Error:", error);

    if (error.message === "Por favor, complete todos los campos.") {
      Swal.fire({
        icon: "error",
        title: "Campos incompletos!",
        text: error.message,
      });
    } else if (error.response && error.response.status === 404) {
      Swal.fire({
        icon: "error",
        title: "Número de documento no encontrado",
        text: "Inténtalo nuevamente o regístrate!",
        footer:
          '<a href="/register">¿Aún no tienes una cuenta? Regístrate aquí</a>',
      });
    } else if (error.response && error.response.status === 400) {
      Swal.fire({
        icon: "error",
        title: "Contraseña incorrecta",
        text: "Inténtalo nuevamente!",
        footer: '<a href="">¿Olvidaste tu contraseña?</a>',
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Ha ocurrido un error",
        text: `Inténtalo nuevamente más tarde. Detalles del error: ${error.message}`,
      });
    }
  }
};


  useEffect(() => {
    if (cookies.get("token")) {
      props.history.push("/menu");
    }
  }, []);

  return (
    <div className="grid-container">
      <div className="bodyy"></div>
      <div className="rectangle">
        <div className="grid-item form-container">
          <div className="form-wrapper">
            <div className="myform form ">
              <div className="logo mb-3"></div>
              <form>
                <div className="form-group">
                  <img
                    className="acad"
                    src={require("../../img/academy_vertical.png")}
                    alt="logo"
                  />
                </div>
                <div className="form-group">
                  <input
                    id="nro_documento"
                    ref={inputRef}
                    disabled={disabled}
                    maxlength="15"
                    className="form-control"
                    placeholder="Ingresa tu documento de identidad"
                    name="nro_documento"
                    onChange={handleChange}
                    onKeyPress={handleN}
                    required
                  />
                </div>
                <div className="form-group password-input">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      maxlength="15"
                      className="form-control"
                      placeholder="Ingresa tu contraseña"
                      name="password"
                      onChange={handleChange}
                      required
                    />
                    <submit onClick={togglePasswordVisibility}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </submit>
                  </div>
                </div>
                <div className="form-group">
                  {/*
                  <a
                    className="text-right link-style"
                    href="#"
                    onClick={handleForgotPasswordClick}
                  >
                    ¿Olvidaste tu contraseña?
                  </a>*/}
                  <Popup
                    open={showForgotPasswordPopup}
                    onClose={handleForgotPasswordClose}
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                  >
                    <div className="popup-content">
                      <div className="popup-header">
                        ¿Olvidaste tu contraseña?
                        <div className="popup-close-button">
                          <FaWindowClose color="#c82333" className="invert-icon" onClick={handleForgotPasswordClose} style={{ cursor: 'pointer' }} />
                        </div>
                      </div>
                      <div className="popup-message">
                        Porfavor ingrese su número de documento, se le enviará
                        un correo para restablecer su contraseña:
                      </div>
                      <br></br>
                      <form>
                        <input
                          id="nro_documento"
                          type="text"
                          ref={inputRef}
                          disabled={disabled}
                          maxlength="15"
                          className="popup-input"
                          placeholder="Ingresa tu documento de identidad"
                          name="nro_documento"
                          onChange={handleChange}
                          onKeyPress={handleN}
                        />
                        <submit
                          className="btn btn-block mybtn btn-success tx-tfm naranjabtn"
                          onClick={enviarCorreo}
                        >
                          Enviar
                        </submit>
                      </form>
                    </div>
                  </Popup>
                </div>
                <div className="col-md-12 text-center ">
                  <button
                    className="btn btn-block mybtn btn-success tx-tfm naranjabtn"
                    onClick={iniciarSesion}
                  >
                    Iniciar sesión
                  </button>
                </div>
                <div className="col-md-12 ">
                  <div className="login-or">
                    <hr className="hr-or"></hr>
                    <span className="span-or">O</span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <p className="text-center ">
                    ¿No tienes una cuenta?
                    <a href="" id="signup">
                      <Link to={"/register"} className="link-style"> Registrate aqui!</Link>
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="image-wrapper">
            <img
              className="logo"
              src={require("../../img/logo_multic2.png")}
              alt="logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;